import React, {
  lazy,
  Suspense,
} from 'react';
import ReactDOM from 'react-dom';
import { initializeIcons, mergeStyles, Spinner } from '@fluentui/react';
import { RecoilRoot, useRecoilState, useRecoilValue } from 'recoil';
import LandingNew from './landingNew';
import reportWebVitals from './reportWebVitals';
import { DebugObserver } from './atoms';
import ThemeProvider from './utils/theme/theme-context';
import 'react-toastify/dist/ReactToastify.css';

// enforce https in production
if (window.location.protocol === 'http:' && process.env.NODE_ENV === 'production') {
  window.location.href = `https://${window.location.href.slice(7)}`;
}

// const AppImport = import('./app'); // preloading
// const App = lazy(() => AppImport);
initializeIcons();

const spinner = mergeStyles({
  height: '100vh',
  overflow: 'hidden'
});

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <ThemeProvider>
        <Suspense fallback={<Spinner label="Loading..." className={spinner} size={3} />}>
          <LandingNew />
        </Suspense>
      </ThemeProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line
reportWebVitals(console.log);
