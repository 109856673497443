import React, { FunctionComponent, useEffect, useState } from 'react';
import { IPivotStyles } from '@fluentui/react';
import fscreen from 'fscreen';
import useAbort from '../utils/hooks/use-abort';
import { UnityCanva } from '../comps/UnityCanva/UnityCanva';

const pivotStyles: Partial<IPivotStyles> = {
  itemContainer: {
    padding: '.5em',
    width: '300px',
    height: '225px'
  }
};

const LandingNew: FunctionComponent = () => {
  const path = window.location.pathname;
  useEffect(() => {
    if (fscreen.fullscreenElement) fscreen.exitFullscreen();
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        left: 0,
        top: 0,
        background: 'blue',
        boxSizing: 'border-box'
      }}>
      <UnityCanva />
    </div>
  );
};

export default LandingNew;
